import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact me`}</h2>
    <p>{`Feel free to reach out on my `}<a href="https://linkedin.com/in/nik3348">{`LinkedIn`}</a>{` or drop me an `}<a href="mailto:nicklai88@live.com">{`Email`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      