import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <h1></h1>
    <h5>{`My name is Nicholas Lai and I make full stack systems; mobile apps, websites, services`}</h5>
    <h5>{`You name it, and I'll get back to you`}</h5>
    <h1></h1>
    <p>{`I enjoy good company with open minds, my interests range from one physical sport (badminton) to almost any video game.
And when I'm not doing either of those I'm ambitiously learning everything I don't know`}</p>
    <p>{`I also enjoy building computers; hardware, software I can do both. Please do check out my work in the projects section and hit me up
at the contact me section`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      