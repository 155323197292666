import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="GoMe" link="https://github.com/nik3348/gome-backend" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This is a simple attempt at a full stack CRUD application using RESTful with ReactJs as the frontend and Echo(Golang) as the backend
    </ProjectCard>
    <ProjectCard title="A* Search" link="https://github.com/nik3348/A-Star-Search" bg="linear-gradient(to right, #632d8c 0%, #ed1e79 100%)" mdxType="ProjectCard">
A* Search visualizer showing a path finding technique from point A to point B. Still a work in progress
    </ProjectCard>
    <ProjectCard title="Discord Bot" link="https://github.com/nik3348/card-game" bg="linear-gradient(to right, #233329  0%, #63D471 100%)" mdxType="ProjectCard">
Discord bot made with Discord.js. This bot is able to shuffle multiple play decks and draw from it randomly.
    </ProjectCard>
    <ProjectCard title="Blockchain" link="https://github.com/nik3348/BlockChain" bg="linear-gradient(to right, #A71D31  0%, #3F0D12 100%)" mdxType="ProjectCard">
This is a blockchain backend made with python and flask. The front end is made with Flutter contact me for a demo.
    </ProjectCard>
    <ProjectCard title="Desktop Builds" link="https://pcpartpicker.com/user/Nik3348/" bg="linear-gradient(to right, #141e30  0%, #243b95 100%)" mdxType="ProjectCard">
Click on completed builds to see what I've made. I mostly build computers for fun and to help my friends
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      